import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import InvoiceForm from './InvoiceForm';
import EditIcon from '@mui/icons-material/Edit';
import EditInvoice from './EditInvoice';

const Index = ({
  currentBatch,
  setInvoiceRaised,
  invoiceData,
  invoiceRaised,
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <div>
      {edit ? (
        <div>
          <EditInvoice setEdit={setEdit} dataToEdit={invoiceData?.data} />
        </div>
      ) : (
        <div>
          {invoiceRaised ? (
            <div>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={2}>
                <Typography>Invoice Status: {invoiceData.status}</Typography>
                {invoiceData?.data?.status === 'Pending' && (
                  <Button onClick={() => setEdit(true)}>
                    <EditIcon />{' '}
                  </Button>
                )}
              </Stack>
            </div>
          ) : (
            <InvoiceForm
              currentBatch={currentBatch}
              setInvoiceRaised={setInvoiceRaised}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
