import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { Box, Button, Typography } from '@mui/material';
import UserTaskDetails from './UserTaskDetails';

const ProjectDetails = ({
  setCurrentProject,
  currentProject,
  currentBatch,
}) => {
  const { project } = currentProject;
  const activeUsers = currentBatch.users.filter(
    (user) => user.status === 'Active'
  );

  return (
    <Box>
      <Button variant='outlined' onClick={() => setCurrentProject(null)}>
        Back
      </Button>
      <Typography mb={2} variant='subtitle2' align='center'>
        {project.title}
      </Typography>
      <TableContainer component={Card}>
        <Table
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'>
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> Name</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Task Done</TableCell>
              <TableCell align='center'>Not Checked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeUsers.map((user) => (
              <UserTaskDetails
                key={user.user} // assuming user.user is unique
                user={user}
                project={project}
                currentBatch={currentBatch}
              />
            ))}
            {/* {projectUser.map((user, index) => (
              <UserTaskDetails
                key={index}
                user={user}
                project={project}
                currentBatch={currentBatch}
              />
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProjectDetails;
