import React, { useEffect, useState } from 'react';
import Feedback from './feedback';
import Invoice from './invoice';
import { Typography, Box } from '@mui/material';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URL_V2 } from '../../../../../links/API_LINKS';
import { CircularProgress } from '@mui/material';

const Index = ({ currentBatch, coach }) => {
  const myCoach =
    coach.coach && coach.coach.length === 1 ? coach.coach[0] : null;

  const gspCoachIDS = ['6178feea7ceec500235ca28b', '6178fdf97ceec500235ca1fb'];

  const checkProjectTrue = gspCoachIDS.includes(myCoach._id);

  // The status is used to add in the loader while the data is being fetched.
  // Replaced post fetching.
  const [evalData, setEvalData] = useState({
    attendanceUpdated: false,
    projectChecked: false,
    status: 'loading',
  });

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(
    currentBatch.coachFeedbacks
  );
  // Set to false
  const [invoiceRaised, setInvoiceRaised] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    status: 'loading',
  });

  useEffect(() => {
    const Authorization = localStorage.getItem('authorization');
    axios
      .get(API_URL_V2 + `/invoice`, {
        headers: { Authorization },
        params: {
          batchId: currentBatch._id,
          coachId: myCoach._id,
        },
      })
      .then((res) => {
        if (res.data.data?.length !== 0) {
          setInvoiceRaised(true);
          setInvoiceData((prev) => ({
            ...prev,
            status: res.data.data[0].status,
            data: res.data.data[0],
          }));
          return;
        }
        setInvoiceData((prev) => ({
          ...prev,
          status: 'Pending',
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(API_URL_V2 + '/coach/evaluation-status', {
        headers: { Authorization },
        params: {
          batchId: currentBatch._id,
        },
      })
      .then((res) => {
        setEvalData((prev) => ({
          ...prev,
          attendanceUpdated: res.data.data?.attendanceUpdated,
          projectChecked: res.data.data?.projectChecked || checkProjectTrue,
          status: 'checked',
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentBatch._id, myCoach._id, invoiceRaised, checkProjectTrue]);

  // Unique message depending on what is pending.
  const evalScreen =
    !evalData.attendanceUpdated && !evalData.projectChecked ? (
      <Typography>Complete Attendance and Evaluations.</Typography>
    ) : !evalData.attendanceUpdated ? (
      <Typography>Complete the Attendance.</Typography>
    ) : (
      <Typography>Complete the Evaluations.</Typography>
    );

  const feedbackScreen = (
    <React.Fragment>
      <Typography fontSize={20}>
        Please complete feedback of current batch to raise an invoice.
      </Typography>
      <Box sx={{ border: '2px solid #e5e7eb', borderRadius: 1.5 }} p={2} my={2}>
        <Feedback
          currentBatch={currentBatch}
          setFeedbackSubmitted={setFeedbackSubmitted}
        />
      </Box>
    </React.Fragment>
  );

  const invoiceScreen = (
    <Invoice
      currentBatch={currentBatch}
      setInvoiceRaised={setInvoiceRaised}
      invoiceRaised={invoiceRaised}
      invoiceData={invoiceData}
    />
  );
  return (
    <Box>
      {!evalData.attendanceUpdated || !evalData.projectChecked ? (
        evalData.status === 'loading' ? (
          <Box display='flex' justifyContent='center' paddingY={5}>
            <CircularProgress />
          </Box>
        ) : (
          evalScreen
        )
      ) : !feedbackSubmitted ? (
        feedbackScreen
      ) : invoiceData.status === 'loading' ? (
        <Box display='flex' justifyContent='center' paddingY={5}>
          <CircularProgress />
        </Box>
      ) : (
        invoiceScreen
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    coach: state.coach,
  };
};

export default connect(mapStateToProps)(Index);
